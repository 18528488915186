import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaymentIcon from '@mui/icons-material/Payment';
import AddCardIcon from '@mui/icons-material/AddCard';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SpeedIcon from '@mui/icons-material/Speed';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CottageIcon from '@mui/icons-material/Cottage';
import { useEffect } from 'react';

const AdminSidebar = () => {

  const navstate = useSelector(state => state.global.navstate);

  const slideNext = (e) => {
    e.target.classList.toggle("opened")
    e.target.nextSibling.classList.toggle("slide-opened")
  }

  useEffect(() => {
    let activelink = document.querySelector('.sidebar a.active');
    if (activelink) {
      let activesection = activelink.closest('.slide');
      if (activesection) {
        activesection.classList.add('slide-opened');
        activesection.previousSibling.classList.add("opened");
      }
    }
  });

  return (
    <div className={'sidebar ' + navstate}>
      <div className="inner">
        <div className="top">
          <span className="logo"></span>
        </div>
        <div className="center">
          <br />
          <ul>
            <li><NavLink to='/'><DashboardIcon className='icon' /><span>Dashboard</span></NavLink></li>
            <li><NavLink to="/benutzer"><PeopleAltIcon className='icon' /><span>Benutzerverwaltung</span></NavLink></li>
          </ul>

          <ul>
            <li><NavLink to="/unterkuenfte"><CottageIcon className='icon' /><span>Unterkünfte</span></NavLink></li>
            <li><NavLink to="/rechnungen"><ReceiptIcon className='icon' /><span>Rechnungen</span></NavLink></li>
            <li><NavLink to="/belohnungen"><CreditScoreIcon className='icon' /><span>Belohnungen</span></NavLink></li>
          </ul>

          <p className='title' onClick={(e) => slideNext(e)}>Allgemeine Daten<ExpandMoreIcon className='icon' /></p>
          <div className="slide">
            <ul>
              <li><NavLink to="/unternehmensdaten"><ApartmentIcon className='icon' /><span>Unternehmensdaten</span></NavLink></li>
              <li><NavLink to="/globale-texte"><FormatAlignLeftIcon className='icon' /><span>Globale Texte</span></NavLink></li>
            </ul>
          </div>

          <p className='title' onClick={(e) => slideNext(e)}>Objektkonfiguration<ExpandMoreIcon className='icon' /></p>
          <div className="slide">
            <ul>
              <li><NavLink to="/objektarten"><HolidayVillageIcon className='icon' /><span>Objektarten</span></NavLink></li>
              <li><NavLink to="/lagebeschreibungen"><EditLocationAltIcon className='icon' /><span>Lagebeschreibungen</span></NavLink></li>
              <li><NavLink to="/ausstattungsmerkmale"><AppRegistrationIcon className='icon' /><span>Ausstattungsmerkmale</span></NavLink></li>
              <li><NavLink to="/nebenkostenoptionen"><PaymentIcon className='icon' /><span>Nebenkostenoptionen</span></NavLink></li>
              <li><NavLink to="/zusatzkostenoptionen"><AddCardIcon className='icon' /><span>Zusatzkostenoptionen</span></NavLink></li>
              <li><NavLink to="/abrechnungsoptionen"><PaymentsIcon className='icon' /><span>Abrechnungsoptionen</span></NavLink></li>
            </ul>
          </div>

          <p className='title' onClick={(e) => slideNext(e)}>Tests<ExpandMoreIcon className='icon' /></p>
          <div className="slide">
            <ul>
              <li><NavLink to="/tests/rechnung"><SpeedIcon className='icon' /><span>Rechnung</span></NavLink></li>
              <li><NavLink to="/tests/gutschrift"><SpeedIcon className='icon' /><span>Gutschrift mit USt.</span></NavLink></li>
              <li><NavLink to="/tests/gutschrift2"><SpeedIcon className='icon' /><span>Gutschrift ohne USt.</span></NavLink></li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  )
}

export default AdminSidebar 