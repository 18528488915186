import { PDFViewer, Page, Image, Text, Document, StyleSheet, View, Font } from '@react-pdf/renderer';
import HelperService from '../../../services/helper.service';

Font.register({ family: 'Noto Sans', src: 'https://fonts.gstatic.com/s/notosans/v6/PIbvSEyHEdL91QLOQRnZ1y3USBnSvpkopQaUR-2r7iU.ttf' });
Font.registerHyphenationCallback(word => (
    [word]
));


const CreateRewardTax = () => {


    const memberResponse = {
        company: 'Testfirma',
        firstname: 'Testi',
        lastname: 'Testemann',
        street: 'Testweg 1',
        postal: '12345',
        city: 'Testort',
        phone: '0381 / 123456789',
        mobile: '0150 / 123456789',
        email: 'testi@testemann.de',
        bank: 'Kreditinstitut ABC',
        iban: 'DE10100000001010101010',
        bic: 'BYLADEM9999',
        tax_id: 'DE101010101',
        tax_num: '081/123/45678',
        tax_office: 'Finanzamt Ribnitz-Damgarten',
    };

    const customerResponse = {
        num: 'K-0815-1',
        'company': 'Egon GbR',
        'companyadd': null,
        'gender': 'male',
        'firstname': 'Egon',
        'lastname': 'Empfänger',
        'street': 'Egonweg 1',
        'postal': '12345',
        'city': 'Egonhausen',
        'ustID': '123456789',
        'taxNum': '123456789',
    };

    const data = {
        'data': {
            'date': '2024-11-12',
            'invoicenumber': 'G-224-1',
            'totalnet': 25,
            'totalgross': 29.75
        },
        'invoiceentries': [
            {
                'name': 'Vermittlung eines neuen Vermieters',
                'vat': 19,
                'qty': 1,
                'singleprice': 25,
                'discount': 0,
            }
        ],
    };

    let invoicedata = data.data;
    let positions = data.invoiceentries;

    const styles = StyleSheet.create({

        bold: {
            fontFamily: 'Helvetica-Bold'
        },

        downloadHolder: {
            margin: '50px auto 0',
            display: 'flex',
            justifyContent: 'center'
        },

        downloadButton: {
            textDecoration: 'none',
            backgroundColor: '#000',
            color: '#fff',
            padding: '8px 20px 5px 20px',
            fontSize: 16,
            borderRadius: '15px 15px 0 0',
            borderBottom: 0,
            fontWeight: 'bold',
            marginTop: '30px'
        },

        page: {
            padding: '37px 60px 75px 75px',
            fontFamily: 'Helvetica',
            fontSize: 9.75
        },

        pageHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },

        pageHeaderLogo: {
            width: 225,
        },

        pageHeaderText: {
            width: 250,
            fontSize: 11.25,
            margin: '3px 0 0 15px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontFamily: 'Helvetica-Bold'
        },

        pageHeaderTextMiddot: {
            fontSize: 24,
            margin: '0 15px 0 0',
            fontFamily: 'Noto Sans'
        },

        pageHeaderTextRight: {
            width: 220,
            fontSize: 15,
            margin: '4px 0 0 0',
            fontFamily: 'Helvetica-Bold',
            textTransform: 'uppercase',
            textAlign: 'right'
        },

        pageContentSender: {
            width: 300,
            fontSize: 6,
            fontFamily: 'Noto Sans',
            marginTop: 43
        },

        pageContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginTop: 23,
            fontSize: 9.75,
            lineHeight: 1.3
        },

        pageText: {
            fontSize: 9.75,
            lineHeight: 1.3
        },

        pageContentHalfLeft: {
            width: '45%'
        },

        pageContentHalfRight: {
            width: '55%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },

        pageContentHalfRightLabel: {
            width: 120,
            textAlign: 'right',
        },

        pageContentHalfRightValue: {
            width: 64,
            textAlign: 'right'
        },

        pageContentHalfRightLabelMTop: {
            width: 120,
            textAlign: 'right',
            marginTop: '15px'
        },

        pageContentHalfRightValueMTop: {
            width: 64,
            textAlign: 'right',
            marginTop: '15px'
        },

        pageSubject: {
            fontSize: 10.5,
            margin: '52px 0 22px',
            fontFamily: 'Helvetica-BoldOblique',
        },

        pageTable: {
            borderTop: '1px solid #cdcdcd',
            borderBottom: '1px solid #cdcdcd',
            padding: '10px 0 10px',
            margin: '20px 0'
        },

        pageTableHead: {
            fontFamily: 'Helvetica-Bold',
            display: 'flex',
            flexDirection: 'row',
            fontSize: 7.5,
            marginBottom: '8px'
        },

        pageTableRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '3px 0 3px'
        },

        pageTablePos: {
            width: '5.5%',
            alignSelf: 'flex-start'
        },

        pageTableName: {
            width: '57.5%',
            paddingRight: 20,
            lineHeight: 1.3
        },

        pageTableVat: {
            width: '8%',
            textAlign: 'center'
        },

        pageTableQty: {
            width: '9%',
            textAlign: 'center'
        },

        pageTableSinglePrice: {
            width: '12%',
            textAlign: 'right'
        },

        pageTableTotalPrice: {
            width: '16%',
            textAlign: 'right'
        },

        pageTableSummary: {
            borderTop: '1px solid #cdcdcd',
            display: 'block',
            paddingTop: 7,
            width: '30%',
            margin: '12px 0 0 auto'
        },

        pageTableSummaryEntry: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 3
        },

        pageTableSummaryLeft: {
            width: '55%',
            textAlign: 'right'
        },

        pageTableSummaryRight: {
            width: '45%',
            textAlign: 'right'
        },

        pageTableSummaryRightBold: {
            width: '45%',
            textAlign: 'right',
            fontFamily: 'Helvetica-Bold',
        },

        pageFooter: {
            position: "absolute",
            bottom: 0,
            height: 50,
            width: '100%',
            margin: '0 60px 0 75px',
            borderTop: '1px solid #cdcdcd',
            color: '#808080',
            display: 'flex',
            flexDirection: 'row',
            fontSize: 7,
            lineHeight: 1.4,
            fontFamily: 'Helvetica-Bold',
            paddingTop: 10
        },

        pageFooterLeft: {
            width: 124
        },

        pageFooterSection1: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: 155,
        },

        pageFooterSection2: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: 200,
        },

        pageFooterSection3: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: 136,
        },

        pageFooterHalfRightLabel: {
            width: 30,
        },

        pageFooterHalfRightValue: {
            width: 65,
        },

        pageFooterHalfRightValueLarge: {
            width: 110,
        },


    });


    let sum = 0;
    positions.forEach(p => {
        sum += p.qty * p.singleprice
    });
    let sumline;
    if (sum > 0 && invoicedata.discount) {
        sumline =
            <View style={styles.pageTableSummaryEntry}>
                <Text style={styles.pageTableSummaryLeft}>Zwischensumme:</Text>
                <Text style={styles.pageTableSummaryRight}>{HelperService.formatPrice(sum)}</Text>
            </View>
    }

    let discountline;
    if (invoicedata.discount) {
        discountline =
            <View style={styles.pageTableSummaryEntry}>
                <Text style={styles.pageTableSummaryLeft}>Rabatt ({invoicedata.discount}%):</Text>
                <Text style={styles.pageTableSummaryRight}>{HelperService.formatPrice(sum - invoicedata.totalnet)}</Text>
            </View>
    }

    let page =
        <Page size="A4" style={styles.page}>
            <View style={styles.pageHeader}>
                <Image src="/logo.png" style={styles.pageHeaderLogo} />

                <View style={styles.pageHeaderText}>
                    {/*    
                    <Text style={styles.pageHeaderTextMiddot}>&middot;</Text>
                    <Text>{memberResponse.firstname} {memberResponse.lastname}</Text>
                    */}
                </View>
                <Text style={styles.pageHeaderTextRight}>Gutschrift</Text>
            </View>

            <Text style={styles.pageContentSender}>{memberResponse.company}  &middot;  {memberResponse.street}  &middot;  {memberResponse.postal} {memberResponse.city}</Text>

            <View style={styles.pageContent}>
                <Text style={styles.pageContentHalfLeft}>
                    {customerResponse.company && customerResponse.company + '\n'}
                    {customerResponse.invoicenoname ? '' : customerResponse.firstname + ' ' + customerResponse.lastname + '\n'}
                    {customerResponse.street}{'\n'}
                    {customerResponse.postal} {customerResponse.city}

                </Text>
                <View style={styles.pageContentHalfRight}>
                    <Text style={styles.pageContentHalfRightLabel}>Gutschriftnummer: </Text>
                    <Text style={styles.pageContentHalfRightValue}>{invoicedata.invoicenumber}</Text>
                    <Text style={styles.pageContentHalfRightLabel}>Kundennummer: </Text>
                    <Text style={styles.pageContentHalfRightValue}>{customerResponse.num}</Text>
                    <Text style={styles.pageContentHalfRightLabel}>Erstellungsdatum: </Text>
                    <Text style={styles.pageContentHalfRightValue}>{invoicedata.date.split("-").reverse().join(".")}</Text>

                    <Text style={styles.pageContentHalfRightLabelMTop}>Ihre Steuernummer: </Text>
                    <Text style={styles.pageContentHalfRightValueMTop}>{customerResponse.taxNum}</Text>
                    <Text style={styles.pageContentHalfRightLabel}>Ihre Umsatzsteuer-ID: </Text>
                    <Text style={styles.pageContentHalfRightValue}>{customerResponse.ustID}</Text>

                </View>
            </View>

            <Text style={styles.pageSubject}>Gutschrift für die Vermittlung eines neuen Vermieters</Text>
            <Text>{customerResponse.gender === 'divers' ? 'Hallo ' + customerResponse.firstname + ' ' + customerResponse.lastname : (customerResponse.gender === 'male' ? 'Sehr geehrter Herr ' : 'Sehr geehrte Frau ') + customerResponse.lastname},{'\n'}{'\n'}
                gemäß userer Vereinbarung schreiben wir Ihnen folgende Leistung gut:</Text>

            <View style={styles.pageTable}>
                <View style={styles.pageTableHead}>
                    <Text style={styles.pageTablePos}>Pos.</Text>
                    <Text style={styles.pageTableName}>Bezeichnung der Leistung</Text>
                    <Text style={styles.pageTableQty}>Menge</Text>
                    <Text style={styles.pageTableSinglePrice}>Einzelpreis</Text>
                    <Text style={styles.pageTableTotalPrice}>Gesamtpreis</Text>
                </View>
                {positions.map((p, index) => {
                    return (
                        <View key={index} style={styles.pageTableRow}>
                            <Text style={styles.pageTablePos}>{index + 1}</Text>
                            <Text style={styles.pageTableName}>{p.name.replace('<br>', '\n')}</Text>
                            <Text style={styles.pageTableQty}>{HelperService.formatNumber(p.qty)}</Text>
                            <Text style={styles.pageTableSinglePrice}>{HelperService.formatPrice(p.singleprice)}</Text>
                            <Text style={styles.pageTableTotalPrice}>{HelperService.formatPrice(p.qty * p.singleprice)}</Text>
                        </View>
                    )
                })
                }

                <View style={styles.pageTableSummary}>
                    {sumline}
                    {discountline}
                    <View style={styles.pageTableSummaryEntry}>
                        <Text style={styles.pageTableSummaryLeft}>Gesamt Netto:</Text>
                        <Text style={styles.pageTableSummaryRight}>{HelperService.formatPrice(invoicedata.totalnet)}</Text>
                    </View>
                    <View style={styles.pageTableSummaryEntry}>
                        <Text style={styles.pageTableSummaryLeft}>zzgl. USt. (19%):</Text>
                        <Text style={styles.pageTableSummaryRight}>{HelperService.formatPrice(invoicedata.totalgross - invoicedata.totalnet)}</Text>
                    </View>
                    <View style={styles.pageTableSummaryEntry}>
                        <Text style={styles.pageTableSummaryLeft}>Gesamtsumme:</Text>
                        <Text style={styles.pageTableSummaryRightBold}>{HelperService.formatPrice(invoicedata.totalgross)}</Text>
                    </View>
                </View>

            </View>

            <Text style={styles.pageText} >
                {invoicedata.reversecharge ? 'Der Rechnungsausweis erfolgt ohne Umsatzsteuer, da die Steuerschuldnerschaft des Leistungsempfängers greift (Reverse-Charge-Verfahren).\n\nDie Umsatzsteuer ist vom Leistungsempfänger anzumelden und abzuführen.\n\n\n' : ''}
                Wir überweisen Ihnen die Gutschrift in Höhe von {HelperService.formatPrice(invoicedata.totalgross)} in den nächsten Tagen auf Ihr Konto.{'\n\n\n'}
                Mit freundlichen Grüßen{'\n\n'}
                Ihr Team von Feworia
            </Text>

            <View style={styles.pageFooter} fixed>
                <Text style={styles.pageFooterLeft}>
                    {memberResponse.company}{'\n'}
                    {memberResponse.street}{'\n'}
                    {memberResponse.postal} {memberResponse.city}
                </Text>

                <View style={styles.pageFooterSection1}>
                    <Text style={styles.pageFooterHalfRightLabel}>Tel.: </Text>
                    <Text style={styles.pageFooterHalfRightValue}>{memberResponse.phone}</Text>
                    <Text style={styles.pageFooterHalfRightLabel}>Mobil: </Text>
                    <Text style={styles.pageFooterHalfRightValue}>{memberResponse.mobile}</Text>
                    <Text style={styles.pageFooterHalfRightLabel}>Mail: </Text>
                    <Text style={styles.pageFooterHalfRightValue}>{memberResponse.email}</Text>
                </View>

                <View style={styles.pageFooterSection2}>
                    <Text style={styles.pageFooterHalfRightLabel}>Bank: </Text>
                    <Text style={styles.pageFooterHalfRightValueLarge}>{memberResponse.bank}</Text>
                    <Text style={styles.pageFooterHalfRightLabel}>IBAN: </Text>
                    <Text style={styles.pageFooterHalfRightValueLarge}>{memberResponse.iban}</Text>
                    <Text style={styles.pageFooterHalfRightLabel}>BIC: </Text>
                    <Text style={styles.pageFooterHalfRightValueLarge}>{memberResponse.bic}</Text>
                </View>

                <View style={styles.pageFooterSection3}>
                    <Text style={styles.pageFooterHalfRightLabel}>USt-ID: </Text>
                    <Text style={styles.pageFooterHalfRightValue}>{memberResponse.tax_id}</Text>
                    <Text style={styles.pageFooterHalfRightLabel}>St.-Nr.: </Text>
                    <Text style={styles.pageFooterHalfRightValue}>{memberResponse.tax_num}</Text>
                    <Text>{memberResponse.tax_office}</Text>
                </View>

            </View>


        </Page>


    const PdfDocument = () => (
        <Document title={invoicedata.invoicenumber}>
            {page}
        </Document>
    );

    return (
        <>
            <div className='content'>
                <br />
                Es muss nur Steuernr. oder Umsatzsteuer-ID des leistenden Unternehmens ausgewiesen werden. Wenn beides da ist, können wir aber wahrscheinlich auch beides anzeigen.

                <PDFViewer className='pdfViewer' style={{ display: 'block', width: '90%', height: '800px', border: 'none', margin: '50px auto 0' }}>
                    <PdfDocument />
                </PDFViewer>
            </div>
        </>
    )


}

export default CreateRewardTax;