import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CottageIcon from '@mui/icons-material/Cottage';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import StarIcon from '@mui/icons-material/Star';
import { useEffect } from 'react';

const Sidebar = () => {

  const navstate = useSelector(state => state.global.navstate);
  const user = useSelector(state => state.global.user);

  const slideNext = (e) => {
    e.target.classList.toggle("opened")
    e.target.nextSibling.classList.toggle("slide-opened")
  }

  useEffect(() => {
    let activelink = document.querySelector('.sidebar a.active');
    if (activelink) {
      let activesection = activelink.closest('.slide');
      if (activesection) {
        activesection.classList.add('slide-opened');
        activesection.previousSibling.classList.add("opened");
      }
    }
  });

  return (
    <div className={'sidebar ' + navstate}>
      <div className="inner">
        <div className="top">
          <span className="logo"></span>
        </div>
        <div className="center">
          <br />
          {
            // Vermittler
            user.data.userType === 'referrer' ?
              <>
                <ul>
                  <li><NavLink to='/'><CreditScoreIcon className='icon' /><span>Meine Belohnungen</span></NavLink></li>
                  <li><NavLink to='/informationen'><TipsAndUpdatesIcon className='icon' /><span>Informationen</span></NavLink></li>
                  <li><NavLink to='/einstellungen'><SettingsIcon className='icon' /><span>Einstellungen</span></NavLink></li>
                </ul>
              </>
              :
              // Vermieter
              <>
                <ul>
                  <li><NavLink to='/'><DashboardIcon className='icon' /><span>Dashboard</span></NavLink></li>
                </ul>
                <br />
                <p className='title opened' onClick={(e) => slideNext(e)}>Unterkünfte<ExpandMoreIcon className='icon' /></p>
                <div className="slide slide-opened">
                  <ul>
                    <li><NavLink to='/unterkuenfte'><CottageIcon className='icon' /><span>Meine Inserate</span></NavLink></li>
                    <li><NavLink to='/gaeste'><PeopleAltIcon className='icon' /><span>Gästeliste</span></NavLink></li>
                    <li><NavLink to='/anfragen'><ListAltIcon className='icon' /><span>Anfragen</span></NavLink></li>
                    <li><NavLink to='/bewertungen'><StarIcon className='icon' /><span>Bewertungen</span></NavLink></li>
                    <li><NavLink to='/statistiken'><AutoGraphIcon className='icon' /><span>Statistiken</span></NavLink></li>
                  </ul>
                </div>

                <p className='title opened' onClick={(e) => slideNext(e)}>Vertragliches<ExpandMoreIcon className='icon' /></p>
                <div className="slide slide-opened">
                  <ul>
                    <li><NavLink to='/rechnungen'><ReceiptIcon className='icon' /><span>Rechnungen</span></NavLink></li>
                    <li><NavLink to='/laufzeiten'><AccessAlarmIcon className='icon' /><span>Laufzeiten</span></NavLink></li>
                  </ul>
                </div>

                <p className='title opened' onClick={(e) => slideNext(e)}>Belohnungssystem<ExpandMoreIcon className='icon' /></p>
                <div className="slide slide-opened">
                  <ul>
                    <li><NavLink to='/belohnungssystem/belohnungen'><CreditScoreIcon className='icon' /><span>Meine Belohnungen</span></NavLink></li>
                    <li><NavLink to='/belohnungssystem/informationen'><TipsAndUpdatesIcon className='icon' /><span>Informationen</span></NavLink></li>
                    <li><NavLink to='/belohnungssystem/einstellungen'><SettingsIcon className='icon' /><span>Einstellungen</span></NavLink></li>
                  </ul>
                </div>
              </>
          }

        </div>
      </div>
    </div >
  )
}

export default Sidebar 