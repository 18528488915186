import { useState, useEffect } from "react";
import FileInput from "./FileInput";
import ImageCropper from "./ImageCropper";
import HelperService from "../../../services/helper.service";
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';


export function Input({ register, type, valasnum, step = '', currency, percentage, name, label, size, onChange, required, min, max, setValue = '', data = '', errors, pattern, readonly, minLength = '', maxLength = '', placeholder = '', info = '', smalltip = '', nolabel = false }) {

    if (data !== '' && currency) {
        let val = parseFloat(data).toFixed(2);
        data = val;
    }

    const handleCurrency = (e) => {
        if (currency) {
            let val = parseFloat(e.target.value).toFixed(2);
            setValue(name, val);
        }
    }

    const emptyErrorMessage = 'Bitte ausfüllen';
    const emailErrorMessage = 'Fehlerhafte E-Mail-Adresse';
    const ibanErrorMessage = 'Fehlerhafte IBAN';
    const bicErrorMessage = 'Fehlerhafte BIC';
    const taxNumErrorMessage = 'Fehlerhafte Steuernummer';
    const taxIdErrorMessage = 'Fehlerhafte ID';
    const phoneErrorMessage = 'Fehlerhafte Telefonnummer';
    const zipCodeErrorMessage = 'Fehlerhafte Postleitzahl';
    const urlErrorMessage = 'Fehlerhafte URL';
    const passwordErrorMessage = 'Min. 8 Zeichen inkl. Groß- und Kleinbuchstabe, Zahl und Sonderzeichen (#?!@$%^&%.*+_:~)';
    const disabled = readonly ? true : false;
    const valnumber = valasnum ? true : false;
    const req = required ? emptyErrorMessage : false;
    const switchPattern = (pattern) => {
        switch (pattern) {
            case 'email':
                return {
                    // eslint-disable-next-line
                    value: /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
                    message: emailErrorMessage
                }

            case 'iban':
                return {
                    value: /^[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?:[ ]?[0-9]{1,2})?$/i,
                    message: ibanErrorMessage
                }

            case 'bic':
                return {
                    value: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/i,
                    message: bicErrorMessage
                }

            case 'taxNum':
                return {
                    value: /^[/\d]{10,13}$/i,
                    message: taxNumErrorMessage
                }

            case 'taxId':
                return {
                    value: /^DE[0-9]{9}$/i,
                    message: taxIdErrorMessage
                }

            case 'password':
                return {
                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&%.*+_:~]).{8,}$/i,
                    message: passwordErrorMessage
                }
            case 'phone':
                return {
                    value: /^[()\s\-+\d]{10,17}$/i,
                    message: phoneErrorMessage
                }
            case 'zipcode':
                return {
                    value: /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/i,
                    message: zipCodeErrorMessage
                }
            case 'url':
                return {
                    value: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/i,
                    message: urlErrorMessage
                }
            default:
                return false;
        }
    }
    return (
        <div className={size}>
            {nolabel ? <label></label> :
                <label>
                    {label + (required ? '*' : '')}
                    {info ? <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={<div className="gw_infotooltip" dangerouslySetInnerHTML={{ __html: info }} />} arrow><HelpOutlineIcon className="infoicon" /></Tooltip> : ''}
                    {smalltip ? <span className="smalltip">({smalltip})</span> : ''}
                </label>
            }
            <div className={"formfieldholder" + (currency ? ' currency' : '') + (percentage ? ' percentage' : '')}>
                <input
                    defaultValue={data}
                    type={type}
                    step={step}
                    placeholder={placeholder}
                    minLength={minLength}
                    min={min}
                    max={max}
                    maxLength={maxLength}
                    {...register(name, {
                        required: req,
                        valueAsNumber: valnumber,
                        pattern: switchPattern(pattern),
                        disabled: disabled,
                        onChange: onChange,
                        onBlur: (e) => { handleCurrency(e) },
                    })}
                />
                {errors[name] && <div className="error">{errors[name].message}</div>}
            </div>
        </div>
    )
}

export function Textarea({ register, name, label, size, required, errors, readonly, getValues, setValue, data = '', minLength = '', maxLength = '', noeditor = false, info = '' }) {
    const emptyErrorMessage = 'Bitte ausfüllen';
    const disabled = readonly ? true : false;
    const req = required ? emptyErrorMessage : false;

    return (
        <div className={size}>
            <label>
                {label + (required ? '*' : '')}
                {info ? <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={<div className="gw_infotooltip" dangerouslySetInnerHTML={{ __html: info }} />} arrow><HelpOutlineIcon className="infoicon" /></Tooltip> : ''}
            </label>

            <div className="formfieldholder">
                {noeditor
                    ?
                    <textarea
                        defaultValue={data}
                        minLength={minLength}
                        maxLength={maxLength}
                        onClick={(e) => {
                            e.target.style.height = e.target.scrollHeight + e.target.offsetTop + "px";
                        }}
                        onInput={(e) => {
                            e.target.style.height = "1px";
                            e.target.style.height = e.target.scrollHeight + e.target.offsetTop + "px";
                            setValue(name, e.target.value, { shouldValidate: true })
                        }}
                        {...register(name, {
                            required: req,
                            disabled: disabled
                        })}
                    />
                    :
                    <>
                        <textarea className="hidden"
                            minLength={minLength}
                            maxLength={maxLength}
                            {...register(name, {
                                required: req,
                                disabled: disabled
                            })}
                        />
                        <CKEditor
                            editor={Editor}
                            config={{
                                heading: {
                                    options: [
                                        { model: 'paragraph', title: 'Absatz', class: 'ck-heading_paragraph' },
                                        { model: 'heading1', view: 'h1', title: 'Überschrift 1', class: 'ck-heading_heading1' },
                                        { model: 'heading2', view: 'h2', title: 'Überschrift 2', class: 'ck-heading_heading2' },
                                        { model: 'heading3', view: 'h3', title: 'Überschrift 3', class: 'ck-heading_heading3' }
                                    ]
                                }
                            }}
                            data=""
                            onReady={editor => {
                                if (getValues(name)) {
                                    editor.setData((getValues(name)))
                                }
                                if (data) {
                                    editor.setData(data)
                                }
                            }}
                            onChange={(event, editor) => {
                                let data = editor.getData();
                                setValue(name, data, { shouldValidate: true })
                            }}
                        />
                    </>
                }
                {errors[name] && <div className="error">{errors[name].message}</div>}
            </div>
        </div>
    )
}

export function Select({ register, options, name, label, size, required, readonly, errors, value, onChange, info = '', nolabel = false }) {

    const selectErrorMessage = 'Bitte auswählen';
    const req = required ? selectErrorMessage : false;
    const disabled = readonly ? true : false;

    return (
        <div className={size}>
            {nolabel ? <label></label> :
                <label>
                    {label + (required ? '*' : '')}
                    {info ? <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={<div className="gw_infotooltip" dangerouslySetInnerHTML={{ __html: info }} />} arrow><HelpOutlineIcon className="infoicon" /></Tooltip> : ''}
                </label>
            }
            <div className="formfieldholder">
                <select
                    {...register(name, {
                        required: req,
                        disabled: disabled,
                        onChange: onChange,
                        value: value
                    })}
                >
                    {Object.entries(options).map((item) => (
                        <option key={item[0]} value={item[0]}>{item[1]}</option>
                    ))}
                </select>
                {errors[name] && <div className="error">{errors[name].message}</div>}
            </div>
        </div>
    );
}

export function Checkbox({ register, name, section = "", num = "", label, size, required, errors, art = "normal", readonly, onChange, value = "" }) {

    const checkboxErrorMessage = 'Bitte aktivieren';
    const req = required ? checkboxErrorMessage : false;
    const disabled = readonly ? true : false;
    return (
        <div className={size + ' checkbox_container'}>
            <div className="formfieldholder">
                <span className={art === 'slider' ? 'slider' : art}>
                    <span className="inputholder">
                        <input
                            type='checkbox'
                            section={section}
                            num={num}
                            defaultChecked={value}
                            {...register(name, {
                                required: req,
                                disabled: disabled,
                                onChange: onChange
                            })}
                        />
                        {art === 'slider' ? <span className="switch"></span> : ''}

                    </span>
                    {label ? <label><span dangerouslySetInnerHTML={{ __html: label }}></span></label> : ''}
                </span>
                {errors[name] && <div className="error">{errors[name].message}</div>}
            </div>
        </div>
    );
}

export function ImageField({ clearErrors, register, name, label, size = '', placeholder, required, errors, width, height, freepos, round, setValue = "", data = "", deleteable = false, info = '', detectChanges }) {

    const emptyErrorMessage = 'Bitte auswählen';
    const req = required ? emptyErrorMessage : false;

    const placeholderimage = round ? <img src={HelperService.getDefaultUserPicture()} alt="" /> : <img src="/set-objectimage.jpg" alt="" />;

    const dummyimg = placeholder ?
        <div className={(round ? 'round ' : '') + "dummyimage"} style={{ height: height + 'px', aspectRatio: width / height }} >{placeholderimage}</div>
        : false;

    const [image, setImage] = useState("");
    const [currentPage, setCurrentPage] = useState('choose-img');
    const [imgAfterCrop, setImgAfterCrop] = useState("");

    // Get and show image if is loaded
    useEffect(() => {
        if (data) {
            setCurrentPage("img-cropped");
            setImgAfterCrop(data);
        }
    }, [data])

    // Invoked when new image file is selected
    const onImageSelected = (selectedImg) => {
        setImage(selectedImg);
        setCurrentPage("crop-img");
    };

    // Generating Cropped Image When Done Button Clicked
    const onCropDone = (imgCroppedArea) => {

        const canvasEle = document.createElement("canvas");
        canvasEle.width = width;
        canvasEle.height = height;

        const context = canvasEle.getContext("2d");

        let imageObj1 = new Image();
        imageObj1.src = image;

        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                canvasEle.width,
                canvasEle.height
            );

            const imgtype = image.substring("data:".length, image.indexOf(";base64"))

            const dataURL = canvasEle.toDataURL(imgtype);
            setImgAfterCrop(dataURL);
            if (detectChanges) {
                detectChanges(name, dataURL);
            }
            if (setValue) {
                setValue(name, dataURL);
            }
            setCurrentPage("img-cropped");
        };

    };

    // Handle Cancel Button Click
    const onCropCancel = () => {
        setCurrentPage("choose-img");
        setImage("");
        setImgAfterCrop("");
        if (detectChanges) {
            detectChanges(name, null);
        }
        if (setValue) {
            setValue(name, "");
        }
    };

    return (
        <div className={size + ' cropperview'}>
            {label ? <label>{label + (required ? '*' : '')}</label> : ''}
            {info ? <div className="info"><Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={<div className="gw_infotooltip" dangerouslySetInnerHTML={{ __html: info }} />} arrow><HelpOutlineIcon className="infoicon" /></Tooltip></div> : ''}
            <div className="formfieldholder">
                {currentPage === "choose-img" ? (
                    <div className="imageholder">
                        {dummyimg}
                        <FileInput register={register} clearErrors={clearErrors} required={required} name={name} errors={errors} setImage={setImage} onImageSelected={onImageSelected} />
                    </div>
                ) : currentPage === "crop-img" ? (
                    <ImageCropper image={image} onCropDone={onCropDone} onCropCancel={onCropCancel} width={width} height={height} freepos={freepos} round={round} />
                ) : (
                    <div className="imageholder">
                        <div>
                            <img src={imgAfterCrop} alt={label} className={(round ? 'round ' : '') + "cropped-img"} />
                            <input
                                type="text"
                                value={imgAfterCrop}
                                {...register(name, {
                                    required: req
                                })}
                                style={{ display: "none" }}
                            />
                        </div>
                        {deleteable && <Tooltip title="Bild löschen" placement="bottom" arrow><button type="button" className="btn delete-image small" onClick={() => { onCropCancel() }}></button></Tooltip>}
                        <FileInput register={register} clearErrors={clearErrors} required={required} name={name} errors={errors} setImage={setImage} onImageSelected={onImageSelected} deleteable={deleteable} />
                    </div>
                )}
            </div>
        </div >
    );

}

export function SavedMessage({ show }) {
    if (show) {
        return (
            <div className='contentoverlay'><span>Daten gespeichert</span></div>
        );
    }
}

export function TopMessage({ message }) {
    if (message) {
        return (
            <div className='contentoverlay'><span>{message}</span></div>
        );
    }
}